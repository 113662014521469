import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image }) => {
  const { pathname } = useLocation()

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          image
        }
      }
    }
  `)

  return (
    <Helmet title={title || data.site.siteMetadata.title}>
      <html lang="nl" />
      <meta
        name="description"
        content={description || data.site.siteMetadata.description}
      />

      <link
        rel="canonical"
        href={`${data.site.siteMetadata.siteUrl}${pathname}`}
      />

      <meta
        property="og:title"
        content={title || data.site.siteMetadata.title}
      />
      <meta
        property="og:image"
        content={
          `${data.site.siteMetadata.siteUrl}${image}` ||
          `${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`
        }
      />
      <meta
        property="og:description"
        content={description || data.site.siteMetadata.description}
      />
      <meta
        property="og:url"
        content={`${data.site.siteMetadata.siteUrl}${pathname}`}
      />
    </Helmet>
  )
}

export default SEO
