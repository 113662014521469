import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="flex flex-col items-center py-6 mt-20 bg-primary">
      <Link
        to="/sitemap/"
        className="block text-sm underline hover:bg-gray-600 hover:text-white"
      >
        Sitemap
      </Link>
      <div className="mt-5 text-sm">
        {currentYear} - Ontwikkeld door{" "}
        <a
          href="https://nl.linkedin.com/in/hessel-middendorp"
          target="_blank"
          rel="nofollow noreferrer noopener"
          className="underline hover:bg-gray-600 hover:text-white"
        >
          Hessel Middendorp
        </a>
      </div>
    </footer>
  )
}

export default Footer
