import React from "react"
import { Link } from "gatsby"

const Header = () => {
  return (
    <div className="py-4 md:py-0 bg-primary">
      <nav className="container mx-auto md:grid md:grid-cols-3">
        <Link className="flex flex-col items-center md:order-2" to="/">
          <div className="text-3xl font-bold uppercase">&#60;imgerd&#62;</div>
          <div className="hidden text-sm sm:block">(de website)</div>
        </Link>
        <ul className="flex items-center justify-around mt-4 md:order-1 md:mt-0">
          <li className="mb-4">
            <Link
              to="/contact/"
              className="underline hover:bg-gray-600 hover:text-white"
            >
              contact
            </Link>
          </li>
          <li className="mt-4">
            <Link
              to="/blog/"
              className="underline hover:bg-gray-600 hover:text-white"
            >
              blogs
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="/over-moi/"
              className="underline hover:bg-gray-600 hover:text-white"
            >
              over moi
            </Link>
          </li>
        </ul>
        <ul className="flex items-center justify-around md:order-3">
          <li className="mb-4">
            <a
              className="underline hover:bg-gray-600 hover:text-white"
              href="https://www.instagram.com/imgerd/"
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              instagram
            </a>
          </li>
          <li className="mt-4">
            <a
              className="underline hover:bg-gray-600 hover:text-white"
              href="https://www.linkedin.com/in/imgerd-friso-479a61125/"
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              linkedin
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Header
