import React from "react"

const StyledHeading = ({ tag: Tag, text, size }) => {
  return (
    <div className="inline-block border-b-4 border-primary origin-left transform -rotate-5">
      <Tag
        className={`inline-block text-2xl ${size} font-bold uppercase origin-left transform -rotate-1`}
      >
        {text}
      </Tag>
    </div>
  )
}

export default StyledHeading
