import React, { useContext } from "react"
import Header from "./Header"
import Footer from "./Footer"
import Toggle from "react-toggle"
import { ThemeContext } from "../context/themeContext"
import { FaMoon, FaSun } from "react-icons/fa"

const Layout = ({ children }) => {
  const { theme, setTheme } = useContext(ThemeContext)

  const handleThemeToggle = () => {
    if (theme === "light") {
      setTheme("dark")
    } else {
      setTheme("light")
    }
  }

  return (
    <div
      className={`${
        theme === "light" ? "theme-light" : "theme-dark"
      } bg-main-white font-mono text-main-text`}
    >
      <Header />
      <div className="flex justify-end">
        <Toggle
          checked={theme === "light" ? true : false}
          icons={{
            checked: <FaSun className="text-sm" />,
            unchecked: <FaMoon className="text-sm" />,
          }}
          onChange={handleThemeToggle}
          className="mt-6 mr-6"
        />
      </div>
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
